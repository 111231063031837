@font-face {
  font-family: 'icons';
  src:  url('../fonts/icons.eot?8ijs4s');
  src:  url('../fonts/icons.eot?8ijs4s#iefix') format('embedded-opentype'),
    url('../fonts/icons.ttf?8ijs4s') format('truetype'),
    url('../fonts/icons.woff?8ijs4s') format('woff'),
    url('../fonts/icons.svg?8ijs4s#icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  /*font-family: 'icons' !important;*/
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.loan-icon .path1:before {
  content: "\e9a8";
  color: rgb(249, 186, 72);
}
.loan-icon .path2:before {
  content: "\e9a9";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.loan-icon .path3:before {
  content: "\e9aa";
  margin-left: -1em;
  color: rgb(249, 186, 72);
}
.loan-icon .path4:before {
  content: "\e9ab";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.loan-icon .path5:before {
  content: "\e9ac";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.loan-icon .path6:before {
  content: "\e9ad";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.credit-card-icon .path1:before {
  content: "\e9ae";
  color: rgb(249, 186, 72);
}
.credit-card-icon .path2:before {
  content: "\e9af";
  margin-left: -1em;
  color: rgb(249, 186, 72);
}
.credit-card-icon .path3:before {
  content: "\e9b0";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.credit-card-icon .path4:before {
  content: "\e9b1";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.credit-card-icon .path5:before {
  content: "\e9b2";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.credit-card-icon .path6:before {
  content: "\e9b3";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.credit-card-icon .path7:before {
  content: "\e9b4";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.credit-card-icon .path8:before {
  content: "\e9b5";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.credit-card-icon .path9:before {
  content: "\e9b6";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.credit-card-icon .path10:before {
  content: "\e9b7";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.credit-card-icon .path11:before {
  content: "\e9b8";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.credit-card-icon .path12:before {
  content: "\e9b9";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.credit-card-icon .path13:before {
  content: "\e9ba";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.bank-icon .path1:before {
  content: "\e9bb";
  color: rgb(51, 51, 51);
}
.bank-icon .path2:before {
  content: "\e9bc";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.bank-icon .path3:before {
  content: "\e9bd";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.bank-icon .path4:before {
  content: "\e9be";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.bank-icon .path5:before {
  content: "\e9bf";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.bank-icon .path6:before {
  content: "\e9c0";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.bank-icon .path7:before {
  content: "\e9c1";
  margin-left: -1em;
  color: rgb(249, 186, 72);
}
.steamroller-icon .path1:before {
  content: "\e994";
  color: rgb(255, 166, 77);
}
.steamroller-icon .path2:before {
  content: "\e995";
  margin-left: -1em;
  color: rgb(255, 166, 77);
}
.steamroller-icon .path3:before {
  content: "\e996";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.steamroller-icon .path4:before {
  content: "\e997";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.steamroller-icon .path5:before {
  content: "\e998";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.mortgage-icon .path1:before {
  content: "\e999";
  color: rgb(249, 186, 72);
}
.mortgage-icon .path2:before {
  content: "\e99a";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.mortgage-icon .path3:before {
  content: "\e99b";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.mortgage-icon .path4:before {
  content: "\e99c";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.mortgage-icon .path5:before {
  content: "\e99d";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.check-icon .path1:before {
  content: "\e99e";
  color: rgb(249, 186, 72);
}
.check-icon .path2:before {
  content: "\e99f";
  margin-left: -1em;
  color: rgb(249, 186, 72);
}
.check-icon .path3:before {
  content: "\e9a0";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.check-icon .path4:before {
  content: "\e9a1";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.check-icon .path5:before {
  content: "\e9a2";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.check-icon .path6:before {
  content: "\e9a3";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.check-icon .path7:before {
  content: "\e9a4";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.check-icon .path8:before {
  content: "\e9a5";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.check-icon .path9:before {
  content: "\e9a6";
  margin-left: -1em;
  color: rgb(249, 186, 72);
}
.check-icon .path10:before {
  content: "\e9a7";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.business-2-icon .path1:before {
  content: "\e95a";
  color: rgb(253, 191, 94);
}
.business-2-icon .path2:before {
  content: "\e95b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.business-2-icon .path3:before {
  content: "\e95c";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.business-2-icon .path4:before {
  content: "\e95d";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.business-2-icon .path5:before {
  content: "\e95e";
  margin-left: -1em;
  color: rgb(225, 230, 233);
}
.business-2-icon .path6:before {
  content: "\e95f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.business-2-icon .path7:before {
  content: "\e960";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.business-2-icon .path8:before {
  content: "\e961";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.business-2-icon .path9:before {
  content: "\e962";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.business-2-icon .path10:before {
  content: "\e963";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.business-2-icon .path11:before {
  content: "\e964";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.business-2-icon .path12:before {
  content: "\e965";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.business-2-icon .path13:before {
  content: "\e966";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.business-2-icon .path14:before {
  content: "\e967";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.business-2-icon .path15:before {
  content: "\e968";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.business-2-icon .path16:before {
  content: "\e969";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.business-2-icon .path17:before {
  content: "\e96a";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.business-card-icon .path1:before {
  content: "\e96b";
  color: rgb(249, 186, 72);
}
.business-card-icon .path2:before {
  content: "\e96c";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.business-card-icon .path3:before {
  content: "\e96d";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.business-card-icon .path4:before {
  content: "\e96e";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.business-card-icon .path5:before {
  content: "\e96f";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.business-card-icon .path6:before {
  content: "\e970";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.business-card-icon .path7:before {
  content: "\e971";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.business-1-icon .path1:before {
  content: "\e972";
  color: rgb(51, 51, 51);
}
.business-1-icon .path2:before {
  content: "\e973";
  margin-left: -1em;
  color: rgb(249, 186, 72);
}
.business-1-icon .path3:before {
  content: "\e974";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.business-1-icon .path4:before {
  content: "\e976";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.business-1-icon .path5:before {
  content: "\e977";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.business-1-icon .path6:before {
  content: "\e978";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.business-1-icon .path7:before {
  content: "\e979";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.business-1-icon .path8:before {
  content: "\e97a";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.business-1-icon .path9:before {
  content: "\e97b";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.business-1-icon .path10:before {
  content: "\e97c";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.business-1-icon .path11:before {
  content: "\e97d";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.money-1-icon .path1:before {
  content: "\e97e";
  color: rgb(249, 186, 72);
}
.money-1-icon .path2:before {
  content: "\e97f";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.money-1-icon .path3:before {
  content: "\e980";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.business2-icon .path1:before {
  content: "\e981";
  color: rgb(255, 208, 91);
}
.business2-icon .path2:before {
  content: "\e982";
  margin-left: -1em;
  color: rgb(172, 179, 186);
}
.business2-icon .path3:before {
  content: "\e983";
  margin-left: -1em;
  color: rgb(230, 233, 238);
}
.business2-icon .path4:before {
  content: "\e984";
  margin-left: -1em;
  color: rgb(43, 59, 78);
}
.business2-icon .path5:before {
  content: "\e985";
  margin-left: -1em;
  color: rgb(43, 59, 78);
}
.business2-icon .path6:before {
  content: "\e986";
  margin-left: -1em;
  color: rgb(50, 74, 94);
}
.business2-icon .path7:before {
  content: "\e987";
  margin-left: -1em;
  color: rgb(50, 74, 94);
}
.business2-icon .path8:before {
  content: "\e988";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.business2-icon .path9:before {
  content: "\e989";
  margin-left: -1em;
  color: rgb(43, 59, 78);
}
.business2-icon .path10:before {
  content: "\e98a";
  margin-left: -1em;
  color: rgb(43, 59, 78);
}
.money-icon .path1:before {
  content: "\e98b";
  color: rgb(51, 51, 51);
}
.money-icon .path2:before {
  content: "\e98c";
  margin-left: -1em;
  color: rgb(249, 186, 72);
}
.money-icon .path3:before {
  content: "\e98d";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.money-icon .path4:before {
  content: "\e98e";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.money-icon .path5:before {
  content: "\e98f";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.money-2-icon .path1:before {
  content: "\e990";
  color: rgb(249, 186, 72);
}
.money-2-icon .path2:before {
  content: "\e991";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.money-2-icon .path3:before {
  content: "\e992";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.money-2-icon .path4:before {
  content: "\e993";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.zip-icon .path1:before {
  content: "\e92a";
  color: rgb(226, 229, 231);
}
.zip-icon .path2:before {
  content: "\e92b";
  margin-left: -1em;
  color: rgb(176, 183, 189);
}
.zip-icon .path3:before {
  content: "\e92c";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.zip-icon .path4:before {
  content: "\e92d";
  margin-left: -1em;
  color: rgb(132, 189, 90);
}
.zip-icon .path5:before {
  content: "\e92e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.zip-icon .path6:before {
  content: "\e92f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.zip-icon .path7:before {
  content: "\e930";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.zip-icon .path8:before {
  content: "\e931";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.jpg-icon .path1:before {
  content: "\e932";
  color: rgb(226, 229, 231);
}
.jpg-icon .path2:before {
  content: "\e933";
  margin-left: -1em;
  color: rgb(176, 183, 189);
}
.jpg-icon .path3:before {
  content: "\e934";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.jpg-icon .path4:before {
  content: "\e935";
  margin-left: -1em;
  color: rgb(80, 190, 232);
}
.jpg-icon .path5:before {
  content: "\e936";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.jpg-icon .path6:before {
  content: "\e937";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.jpg-icon .path7:before {
  content: "\e938";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.jpg-icon .path8:before {
  content: "\e939";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.ppt-icon .path1:before {
  content: "\e93a";
  color: rgb(226, 229, 231);
}
.ppt-icon .path2:before {
  content: "\e93b";
  margin-left: -1em;
  color: rgb(176, 183, 189);
}
.ppt-icon .path3:before {
  content: "\e93c";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.ppt-icon .path4:before {
  content: "\e93d";
  margin-left: -1em;
  color: rgb(241, 86, 66);
}
.ppt-icon .path5:before {
  content: "\e93e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ppt-icon .path6:before {
  content: "\e93f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ppt-icon .path7:before {
  content: "\e940";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ppt-icon .path8:before {
  content: "\e941";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.xls-icon .path1:before {
  content: "\e942";
  color: rgb(226, 229, 231);
}
.xls-icon .path2:before {
  content: "\e943";
  margin-left: -1em;
  color: rgb(176, 183, 189);
}
.xls-icon .path3:before {
  content: "\e944";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.xls-icon .path4:before {
  content: "\e945";
  margin-left: -1em;
  color: rgb(132, 189, 90);
}
.xls-icon .path5:before {
  content: "\e946";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.xls-icon .path6:before {
  content: "\e947";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.xls-icon .path7:before {
  content: "\e948";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.xls-icon .path8:before {
  content: "\e949";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.doc-icon .path1:before {
  content: "\e94a";
  color: rgb(226, 229, 231);
}
.doc-icon .path2:before {
  content: "\e94b";
  margin-left: -1em;
  color: rgb(176, 183, 189);
}
.doc-icon .path3:before {
  content: "\e94c";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.doc-icon .path4:before {
  content: "\e94d";
  margin-left: -1em;
  color: rgb(80, 190, 232);
}
.doc-icon .path5:before {
  content: "\e94e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.doc-icon .path6:before {
  content: "\e94f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.doc-icon .path7:before {
  content: "\e950";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.doc-icon .path8:before {
  content: "\e951";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.pdf-icon .path1:before {
  content: "\e952";
  color: rgb(226, 229, 231);
}
.pdf-icon .path2:before {
  content: "\e953";
  margin-left: -1em;
  color: rgb(176, 183, 189);
}
.pdf-icon .path3:before {
  content: "\e954";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.pdf-icon .path4:before {
  content: "\e955";
  margin-left: -1em;
  color: rgb(241, 86, 66);
}
.pdf-icon .path5:before {
  content: "\e956";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.pdf-icon .path6:before {
  content: "\e957";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.pdf-icon .path7:before {
  content: "\e958";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.pdf-icon .path8:before {
  content: "\e959";
  margin-left: -1em;
  color: rgb(202, 209, 216);
}
.shield-icon:before {
  content: "\e900";
}
.document-icon .path1:before {
  content: "\e901";
  color: rgb(249, 186, 72);
}
.document-icon .path2:before {
  content: "\e902";
  margin-left: -1em;
  color: rgb(249, 186, 72);
}
.document-icon .path3:before {
  content: "\e903";
  margin-left: -1em;
  color: rgb(249, 186, 72);
}
.document-icon .path4:before {
  content: "\e904";
  margin-left: -1em;
  color: rgb(249, 186, 72);
}
.document-icon .path5:before {
  content: "\e905";
  margin-left: -1em;
  color: rgb(249, 186, 72);
}
.document-icon .path6:before {
  content: "\e906";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.document-icon .path7:before {
  content: "\e907";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.document-icon .path8:before {
  content: "\e908";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.document-icon .path9:before {
  content: "\e909";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.document-icon .path10:before {
  content: "\e90a";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.document-icon .path11:before {
  content: "\e90b";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.list-icon .path1:before {
  content: "\e90c";
  color: rgb(249, 186, 72);
}
.list-icon .path2:before {
  content: "\e90d";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.list-icon .path3:before {
  content: "\e90e";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.list-icon .path4:before {
  content: "\e90f";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.list-icon .path5:before {
  content: "\e910";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.list-icon .path6:before {
  content: "\e911";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.list-icon .path7:before {
  content: "\e912";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.list-icon .path8:before {
  content: "\e913";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.list-icon .path9:before {
  content: "\e914";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.list-icon .path10:before {
  content: "\e915";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.list-icon .path11:before {
  content: "\e916";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.list-icon .path12:before {
  content: "\e917";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.list-icon .path13:before {
  content: "\e918";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.list-icon .path14:before {
  content: "\e919";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.businessman-icon .path1:before {
  content: "\e91a";
  color: rgb(51, 51, 51);
}
.businessman-icon .path2:before {
  content: "\e91b";
  margin-left: -1em;
  color: rgb(249, 186, 72);
}
.businessman-icon .path3:before {
  content: "\e91c";
  margin-left: -1em;
  color: rgb(249, 186, 72);
}
.businessman-icon .path4:before {
  content: "\e91d";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.businessman-icon .path5:before {
  content: "\e91e";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.report-icon .path1:before {
  content: "\e91f";
  color: rgb(51, 51, 51);
}
.report-icon .path2:before {
  content: "\e920";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.report-icon .path3:before {
  content: "\e921";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.report-icon .path4:before {
  content: "\e922";
  margin-left: -1em;
  color: rgb(249, 186, 72);
}
.business-icon .path1:before {
  content: "\e923";
  color: rgb(249, 186, 72);
}
.business-icon .path2:before {
  content: "\e924";
  margin-left: -1em;
  color: rgb(249, 186, 72);
}
.business-icon .path3:before {
  content: "\e925";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.business-icon .path4:before {
  content: "\e926";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.business-icon .path5:before {
  content: "\e927";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.business-icon .path6:before {
  content: "\e928";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.business-icon .path7:before {
  content: "\e929";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.people2-icon:before {
  content: "\e975";
}
